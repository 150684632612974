import React, { useEffect, useState } from "react";
import "./App.css";
import openSourceTestVideo2 from "./assets/OpenSourceTestVideo.mp4";

const App = () => {
  const [lastKey, setLastKey] = useState<string>("");

  useEffect(() => {
    window.addEventListener("keydown", (e) => {
      setLastKey(e.key);
    });
  }, []);

  return (
    <div className="App">
      <div className="App__backkground">
        <h1 className="App__header">Hello World</h1>
        <p className="App__text">React Typescript Test</p>
        <p className="App__text">{`Last key pressed: ${lastKey}`}</p>
        <video className="App__video" width="320" height="200" controls loop>
          <source src={openSourceTestVideo2} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default App;
